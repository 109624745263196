<!-- eslint-disable prettier/prettier -->
<template>
  <mobile-screen :header="true" screen-class="resources-screen gray-bg icon-app1">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1" :helpOnline="Boolean(
        helpOnline[helpSlug] &&
        helpOnline[helpSlug].name &&
        helpOnline[helpSlug].content
      )
        " :helpOnlineRoute="{
    name:
      this.backLinkName.indexOf('r_edit') !== -1
        ? 'r_resources-help-online-edit-translations'
        : 'r_resources-help-online-add-translations',
  }">
        <template v-slot:left>
          <button @click="initSubmit">
            <icon icon="#cx-hea1-arrow-left" />
          </button>
        </template>
        <div class="component-title">
          {{ displayLabelName("resources", componentTitle, componentTitle) }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <VeeForm :key="formKey" v-if="translationFields && translationFields.length" ref="translateWordsForm"
      @submit="submitForm" v-slot="{ errors }" class="form inline-input edit-form"
      :initial-values="{ translationFields: [...translationFields] }" novalidate>
      <div
        style="width: 100%; color: #999999; padding: 10px; padding-bottom: 0px; padding-top: 15px; padding-right: 20px; text-align: right;">
        <span style="cursor: pointer;" @click="applyDefaultTranslationToAll()">{{
          displayLabelName(
            "global",
            "translations",
            "apply-default-to-all"
          )
        }}</span>
      </div>
      <ul class="clebex-item-section">
        <li v-for="(lang, idx) in languages.data" :key="lang.name" class="clebex-item-section-item full-right-underline">
          <div v-for="(field, key) in translationFields[idx].attributes" :key="key" :class="{
            'has-error':
              errors[`translationFields[${idx}].attributes.${key}`],
          }" class="clebex-item-content-wrapper">
            <div class="error-message" v-if="errors[`translationFields[${idx}].attributes.${key}`]">
              {{ errors[`translationFields[${idx}].attributes.${key}`] }}
            </div>
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" :for="`translationFields[${idx}].attributes.${key}`">
                {{ lang.name }}<span v-if="idx === 0">*</span>
              </label>
              <Field :id="`translationFields[${idx}].attributes.${key}`"
                :name="`translationFields[${idx}].attributes.${key}`" as="input" type="text"
                v-on:keyup="setDefaultValue(idx, $event.target.value)" />
            </div>
          </div>
        </li>
      </ul>
      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState } from "vuex";
import { getLang } from "@/services/http-service";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "TranslateWords",
  mixins: [helpOnlineMixin],
  data() {
    return {
      defaultValue: "",
      formKey: 0,
      helpSlug: "resources-translation"
    };
  },
  computed: {
    ...mapState("language", ["languages"]),
    ...mapState("resource", ["resource", "resourceTranslations"]),
    translationFields() {
      const currentLang = getLang();

      function sortFn(a, b) {
        if (a.locale === currentLang && b.locale !== currentLang) {
          return -1;
        }

        if (a.locale !== currentLang && b.locale === currentLang) {
          return 1;
        }

        return 0;
      }

      const { languages, resource, resourceTranslations } = this;
      // edit
      if (
        languages &&
        languages.data &&
        languages.data.length &&
        resource &&
        resource.data &&
        resource.data.translations
      ) {
        const sortedLanguages = languages.data.sort(sortFn);
        return sortedLanguages.map((item, idx) => {
          const defaultObj = {
            locale: item.locale,
            attributes: {
              name: ""
            }
          };
          if (resourceTranslations && resourceTranslations.length) {
            return {
              ...resourceTranslations[idx],
              attributes: {
                ...resourceTranslations[idx].attributes
              }
            };
          } else if (resource.data.translations.length) {
            const sortedSavedTranslations = resource.data.translations.sort(
              sortFn
            );
            const savedTr = sortedSavedTranslations.find(
              tr => tr.locale === item.locale
            );
            if (savedTr) {
              return {
                ...savedTr,
                attributes: {
                  ...savedTr.attributes
                }
              };
            } else {
              return defaultObj;
            }
          } else {
            return defaultObj;
          }
        });
      } else if (languages && languages.data && languages.data.length) {
        const sortedLanguages = languages.data.sort(sortFn);
        // add
        return sortedLanguages.map((item, idx) => {
          const defaultObj = {
            locale: item.locale,
            attributes: {
              name: ""
            }
          };
          if (resourceTranslations && resourceTranslations.length) {
            return {
              ...resourceTranslations[idx],
              attributes: {
                ...resourceTranslations[idx].attributes
              }
            };
          } else {
            return defaultObj;
          }
        });
      }
      return null;
    }
  },
  created() {
    if (this.translationFields) {
      this.defaultValue = this.translationFields[0].attributes.name;
    }
  },
  methods: {
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    },
    submitForm(values) {
      this.$store.commit(
        "resource/setResourceTranslations",
        [
          ...this.translationFields.map((item, idx) => {
            if (values.translationFields[idx].attributes.name) {
              return {
                locale: item.locale,
                attributes: values.translationFields[idx].attributes
              };
            } else {
              return {
                locale: item.locale,
                attributes: item.attributes.name
                  ? item.attributes
                  : values.translationFields[0].attributes
              };
            }
          })
        ],
        {
          root: true
        }
      );
      this.$router.push({
        name: this.backLinkName,
        params: this.$route.params
      });
    },
    applyDefaultTranslationToAll() {
      if (this.defaultValue) {
        for (var i = 0; i < this.translationFields.length; i++) {
          this.translationFields[i].attributes.name = this.defaultValue;
        }
        this.formKey++;
      }
    },
    setDefaultValue(id, value) {
      if (id == 0) {
        this.defaultValue = value;
      }
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    },
    componentTitle: {
      type: String,
      required: true
    }
  }
};
</script>
